<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <editHeader
      :isShow="isShow"
      :btn="btn"
      :strForm="statForm"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @refresh="buttonRefresh"
      @submit="submit('statForm')"
    >
      <div slot="functionButton" class="vg_ml_8" v-if="isShow">
        <el-button type="primary" size="small" @click="exportExcel()">打印预览</el-button>
      </div>
      <div slot="otherButton">
        <div>
          <span style="margin-right: 2px">结算单号:</span>
          <el-input size="mini" style="width: 150px" disabled v-model="statForm.stat_no" show-word-limit></el-input>
        </div>
      </div>
    </editHeader>
    <el-form ref="statForm" :model="statForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="出运发票号" prop="tran_no">
            <el-input
              v-model="statForm.tran_no"
              maxlength="30"
              @input="val => (statForm.tran_no = helper.transverseTranNo(val).trim())"
              show-word-limit
              placeholder="请填写出运发票号"
              clearable
            >
              <template slot="append">
                <el-link type="primary" @click="dialogTableVisible = true" :disabled="isShow">选择</el-link>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户简称" prop="cust_id">
            <el-select v-model="statForm.cust_id" size="small" placeholder="请选择客户简称" filterable>
              <el-option v-for="item in custOptionList" :key="item.cust_id" :label="item.cust_abbr" :value="item.cust_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="开船日期:" prop="tran_date">
            <el-date-picker v-model="statForm.tran_date" type="date" placeholder="选择开船日期"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="结算金额" prop="stat_total">
            <el-input v-model="statForm.stat_total" disabled placeholder="请填写结算金额"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_id">
            <el-select v-model="statForm.cptt_id" size="small" placeholder="请选择公司抬头" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="待审核状态" prop="stat_state">
            <el-select v-model="statForm.stat_state" size="small" placeholder="请选择待审核状态" filterable>
              <el-option v-for="item in statStateList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="备注" prop="stat_remark">
            <el-input
              v-model="statForm.stat_remark"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              maxlength="255"
              show-word-limit
              placeholder="请填写备注"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <div class="vg_mb_16">
            <!--            <el-button type="primary" plain size="small" @click="openPinv">导入采购发票</el-button>-->
            <!--            <el-button type="primary" plain size="small" @click="openTinv">导入转开发票</el-button>-->
            <el-button type="primary" plain size="small" @click="openInvoice">导入发票</el-button>
            <el-button type="danger" plain size="small" @click="delPinv">删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <PinvList
            ref="PinvList"
            v-if="statForm.stat_part_list"
            :statForm="statForm"
            @calcPrice="calcPrice"
            @handleSelectionChange="handleSelectionChange"
          ></PinvList>
        </el-col>
      </el-row>
      <el-dialog title="导入出运发票号" width="70%" :visible.sync="dialogTableVisible">
        <TranList @tranConfirm="tranConfirm"></TranList>
      </el-dialog>
      <el-dialog title="导入采购发票" width="70%" :visible.sync="dialogTableVisible1">
        <ImportPinv ref="ImportPinv" @childConfirmPurchaes="childConfirmPurchaes" @childChanelPurchaes="childChanelPurchaes"></ImportPinv>
      </el-dialog>
      <el-dialog title="导入转开发票" width="70%" :visible.sync="dialogTableVisible2">
        <ImportTinv ref="ImportTinv" @childConfirmTinv="childConfirmTinv" @childChanelTinv="childChanelTinv"></ImportTinv>
      </el-dialog>
      <el-dialog title="导入发票" width="70%" :visible.sync="dialogTableVisible3">
        <ImportInvoice ref="ImportInvoice" @childConfirmInvoice="childConfirmInvoice" @childChanelInvoice="childChanelInvoice"></ImportInvoice>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="statForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { statAPI } from '@api/modules/stat';
import { cpttAPI } from '@api/modules/comptitle';
import { custAPI } from '@/api/modules/cust';
import editHeader from '@/views/component/editHeaderLine';
import inputUser from '@/views/component/inputUser';
import { BigNumber } from 'bignumber.js';
import TranList from './Component/TranList.vue';
import PinvList from './Component/PinvList.vue';
import ImportPinv from './Component/ImportPinv.vue';
import ImportTinv from './Component/ImportTinv.vue';
import ImportInvoice from './Component/ImportInvoice.vue';
import { downloadFile } from '@api/public';
import helper from '@assets/js/helper';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'StatEditMain',
  computed: {
    helper() {
      return helper;
    }
  },
  components: {
    editHeader,
    inputUser,
    TranList,
    PinvList,
    ImportPinv,
    ImportTinv,
    ImportInvoice
  },
  data() {
    return {
      rules: {
        tran_no: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      statForm: {
        tran_no: ''
      },
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: false,
      statStateList: [
        { id: 0, label: '可审核' },
        { id: 1, label: '不可审核' }
      ],
      custOptionList: [],
      cpttList: [],
      dialogTableVisible: false,
      dialogTableVisible1: false,
      dialogTableVisible2: false,
      dialogTableVisible3: false,
      selectList: [],
      statPartListCopy: []
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getStatInfo();
      this.getCptt();
      this.initCust();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(err => console.error(err));
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(err => console.error(err));
    },
    // 保存
    saveInfo() {
      this.$refs.PinvList.searchForm.prod_bhsname = '';
      let statForm = JSON.parse(JSON.stringify(this.statForm));
      statForm.stat_date = parseInt(Number(new Date(statForm.stat_date).getTime()) / 1000);
      if (statForm.tran_date) {
        statForm.tran_date = parseInt(Number(new Date(statForm.tran_date).getTime()) / 1000);
      }
      this.statPartListCopy = this.statPartListCopy.filter(item => statForm.stat_part_list.every(item1 => item.stat_part_id !== item1.stat_part_id));
      for (let i in this.statPartListCopy) {
        this.statPartListCopy[i].destroy_flag = 1;
      }
      statForm.stat_part_list = statForm.stat_part_list.concat(this.statPartListCopy);
      post(statAPI.editStat, statForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$refs.PinvList.searchForm.prod_bhsname = '';
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(err => console.error(err));
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // 打开导入采购发票弹框
    openPinv() {
      if (!this.statForm.tran_no) {
        return this.$message.warning('请先选择或输入出运发票号');
      }
      this.dialogTableVisible1 = true;
      setTimeout(() => {
        this.$refs.ImportPinv.tranNo = this.statForm.tran_no;
        let temp = [];
        this.statForm.stat_part_list.forEach(item => {
          temp.push(item.pinv_part_id);
        });
        this.$refs.ImportPinv.pinv_part_ids = temp.join(',');
        this.$refs.ImportPinv.initData();
      }, 300);
    },
    // 打开导入转开发票弹框
    openTinv() {
      if (!this.statForm.tran_no) {
        return this.$message.warning('请先选择出运发票号');
      }
      this.dialogTableVisible2 = true;
      setTimeout(() => {
        this.$refs.ImportTinv.tranNo = this.statForm.tran_no;
        let temp = [];
        this.statForm.stat_part_list.forEach(item => {
          temp.push(item.tinv_part_id);
        });
        this.$refs.ImportTinv.tinv_part_ids = temp.join(',');
        this.$refs.ImportTinv.initData();
      }, 300);
    },
    // 删除值
    delPinv() {
      let temp = [];
      for (let i = 0; i < this.selectList.length; i++) {
        for (let j = 0; j < this.statForm.stat_part_list.length; j++) {
          if (this.selectList[i].key === this.statForm.stat_part_list[j].key) {
            this.statForm.stat_part_list[j].destroy_flag = 1;
            temp.push(j);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        this.statForm.stat_part_list.splice(i, 1);
      }
      for (let i = 0; i < this.statForm.stat_part_list.length; i++) {
        this.statForm.stat_part_list[i].key = Number(i);
      }
      this.calcPrice();
    },
    // 计算结算金额
    calcPrice() {
      let totalA = new BigNumber(0);
      for (let i = 0; i < this.statForm.stat_part_list.length; i++) {
        totalA = totalA.plus(this.statForm.stat_part_list[i].pinv_part_subtotal ? this.statForm.stat_part_list[i].pinv_part_subtotal : 0);
      }
      totalA = totalA.toNumber();
      this.statForm.stat_total = this.helper.haveFour(totalA);
    },
    // 弹框确认值
    childConfirmPurchaes(row) {
      let temp = JSON.parse(JSON.stringify(row));
      // temp = temp.filter(item => this.statForm.stat_part_list.every(item1 => item.pinv_id !== item1.pinv_id));
      for (let i = 0; i < temp.length; i++) {
        temp[i].prod_tnweight = this.helper.reservedDigits(temp[i].prod_tnweight);
        temp[i].pinv_part_num = this.helper.haveFour(temp[i].pinv_part_num);
        temp[i].pinv_part_price = this.helper.haveFour(temp[i].pinv_part_price);
        temp[i].pinv_actu_nos = temp[i].invoice_no;
        temp[i].pinv_cptt_name = temp[i].cptt_bname;
        temp[i].pinv_cptt_id = temp[i].cptt_id;
        temp[i].tinv_id = 0;
      }
      this.statForm.stat_part_list = this.statForm.stat_part_list.concat(temp);
      for (let i = 0; i < this.statForm.stat_part_list.length; i++) {
        this.statForm.stat_part_list[i].key = Number(i);
      }
      this.calcPrice();
      this.dialogTableVisible1 = false;
    },
    // 取消选择值
    childChanelPurchaes() {
      this.dialogTableVisible1 = false;
    },
    // 转开发票弹框确认值
    childConfirmTinv(row) {
      let temp = JSON.parse(JSON.stringify(row));
      temp = temp.filter(item => this.statForm.stat_part_list.every(item1 => item.tinv_id !== item1.tinv_id));
      let temp1 = [];
      for (let i = 0; i < temp.length; i++) {
        let item = {};
        item.pinv_no = temp[i].tinv_no;
        item.supp_abbr = temp[i].supp_abbr;
        item.podr_no = temp[i].podr_no;
        item.pinv_actu_nos = temp[i].invoice_no;
        item.prod_bhsname = temp[i].prod_hsname;
        item.prod_tnweight = this.helper.reservedDigits(temp[i].prod_tnweight);
        item.pinv_part_unit = temp[i].pinv_part_unit;
        item.pinv_part_num = this.helper.haveFour(temp[i].pinv_part_num);
        item.pinv_part_price = temp[i].prod_price;
        item.prod_unit = temp[i].prod_unit;
        item.pinv_part_subtotal = this.helper.haveFour(temp[i].tinv_invtotal);
        item.pinv_cptt_name = temp[i].cptt_bname;
        item.pinv_cptt_id = temp[i].cptt_id;
        item.tinv_id = temp[i].tinv_id;
        item.tinv_part_id = temp[i].tinv_part_id;
        temp1.push(item);
      }
      this.statForm.stat_part_list = this.statForm.stat_part_list.concat(temp1);
      for (let i = 0; i < this.statForm.stat_part_list.length; i++) {
        this.statForm.stat_part_list[i].key = Number(i);
      }
      this.calcPrice();
      this.dialogTableVisible2 = false;
    },
    //打开导入发票弹窗
    openInvoice() {
      if (!this.statForm.tran_no) {
        return this.$message.warning('请先选择出运发票号');
      }
      this.dialogTableVisible3 = true;
      setTimeout(() => {
        this.$refs.ImportInvoice.tranNo = this.statForm.tran_no;
        let temp = [];
        let temp2 = [];
        this.statForm.stat_part_list.forEach(item => {
          temp.push(item.pinv_part_id);
          temp2.push(item.tinv_id);
        });
        this.$refs.ImportInvoice.pinv_part_ids = temp.join(',');
        this.$refs.ImportInvoice.tinv_ids = temp2.join(',');
        this.$refs.ImportInvoice.initData();
      }, 300);
    },
    //导入发票确认值
    childConfirmInvoice(val) {
      //添加数据
      this.statForm.stat_part_list = this.statForm.stat_part_list.concat(val);
      //计算金额，关闭窗口，赋值key
      for (let i = 0; i < this.statForm.stat_part_list.length; i++) {
        this.statForm.stat_part_list[i].key = Number(i);
      }
      this.calcPrice();
      this.dialogTableVisible3 = false;
    },
    // 导入发票取消选择值
    childChanelInvoice() {
      this.dialogTableVisible3 = false;
    },
    // 转开发票取消选择值
    childChanelTinv() {
      this.dialogTableVisible2 = false;
    },
    // 子表勾选值
    handleSelectionChange(row) {
      this.selectList = JSON.parse(JSON.stringify(row));
    },
    // 选择出运发票号
    tranConfirm(row) {
      this.statForm.tran_no = helper.transverseTranNo(row.tran_no).trim();
      this.statForm.tran_id = row.tran_id;
      this.statForm.cptt_id = row.cptt_aid;
      this.statForm.cust_id = row.cust_id;
      this.statForm.stat_part_list = [];
      this.statForm.stat_total = '0.0000';
      this.dialogTableVisible = false;
    },

    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    // 获取客户
    initCust() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custOptionList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(err => console.error(err));
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getStatInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(statAPI.getStatById, { stat_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.statForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.statForm.stff_name;
            this.stffForm.dept_name = this.statForm.dept_name;
            this.stffForm.dept_team_name = this.statForm.dept_team_name;
            this.stffForm.user_id = this.statForm.user_id;
            this.stffForm.dept_id = this.statForm.dept_id;
            this.stffForm.stff_id = this.statForm.stff_id;
            this.statPartListCopy = JSON.parse(JSON.stringify(this.statForm.stat_part_list));

            this.calcChange();
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    // 计算
    calcChange() {
      if (this.statForm.tran_date) {
        this.statForm.tran_date = Number(this.statForm.tran_date) * 1000;
      } else {
        this.statForm.tran_date = null;
      }
    },
    exportExcel() {
      let requestBody = { type: 0, stat_id: this.statForm.stat_id };
      statAPI.exportStat(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.pdf_url, fileName: data.fileName, type: '.pdf', open: true });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}

.vd_word {
  word-wrap: break-word;
  word-break: normal;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}
</style>
